/*------------------------------------*\
    Form ( use Gravity Forms )
    Style write by Gravity Form, to force use !important
   .gform_wrapper
\*------------------------------------*/

.gform_wrapper {

    // :focus { outline: .1rem dashed var(--c-brand-accent); outline-offset: .2rem; }

    // color: var(--c-black);



    // Placehlder
    ::placeholder { color: var(--c-black); }



    // Confirmation Message
    .gform_confirmation_message {}



    // Validation
    .gform_validation_errors {

        // color: var(--c-white) !important;
        // border-color: var(--c-brand-accent) !important;
        // background-color: var(--c-brand-light) !important;

        margin-bottom: 4rem !important;

        // & > h2 { color: var(--c-white) !important; }

    }// .gform_validation_errors

    .validation_error,
    .validation_message {

        // color: var(--c-white) !important;
        // border-color: var(--c-brand-accent) !important;
        // background-color: var(--c-brand-light) !important;

    }



    // Required
    .gfield_required { color: currentColor !important; }



    // Error

    .gfield.gfield_error {
        background-color: transparent !important;
        border-width: 0 !important;
        padding: 0 !important;
    }

    .gfield_error label,
    .gfield_error legend {
        color: var(--c-error) !important;
    }

    .gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    .gfield_error textarea,
    .gfield_error select,
    .gfield_error [aria-invalid=true] {
        border-color: var(--c-error);
        background-color: var(--c-grey-lighter);
    }

    .gfield_error div.ginput_complex.ginput_container label,
    .gfield_error ul.gfield_checkbox,
    .gfield_error ul.gfield_radio {
        border-color: var(--c-error);
    }



    // Heading
    .gform_heading {

        .gform_title {}// .gform_title

        .gform_description {}// .gform_description

    }// .gform_heading



    // General
    .gfield {

        margin-top: 2rem;

    }// .gfield

    input[type=text],
    input[type=email],
    input[type=tel],
    textarea,
    select {

        color: var(--c-black);
        background-color: var(--c-grey-lighter);

        border-color: currentColor;
        border-style: solid;
        border-radius: .25rem;
        border-width: .15rem;

        padding: 1rem !important;
        width: 100% !important;
        line-height: 2;

    }

    select { height: 5rem; }

    .gfield_label,
    .ginput_complex label {

        font-size: 1.8rem !important;

    }



    // Container
    //.ginput_container { margin-top: 0 !important; }


    // Privacy checkbox
    // use gravity consent
    .ginput_container_consent {

        display: flex;
        align-items: center;

        input[type=checkbox] {

            flex-shrink: 0;
            width: 3rem !important;
            height: 3rem;
            margin-top: 0 !important;
            margin-right: 2rem;
            vertical-align: middle;
            accent-color: var(--c-accent);

        }

        .gfield_consent_label {

            line-height: 1.4;

            a {
                color: currentColor;
                border-bottom: .1rem solid currentColor;
            }

        }// .gfield_consent_label

    }
    .gfield_error .ginput_container_consent {

        .gfield_consent_label {
            color: var(--c-error);
        }

    }



    // Footer
    .gform_footer {

        margin: 4rem auto 0 !important;
        padding: 0 !important;

    }



    // Submit
    // input[type=submit] covented in tag button in extras.php
    .gform_footer button,
    .gform_footer input[type=submit] {

        margin-left: 2rem;
        margin-top: 4rem;

        display: inline-flex;
    	justify-content: center;
    	align-items: center;

    	min-width: 14rem;
    	min-height: 4.6rem;
    	border: .2rem solid currentColor;
        padding: 2rem;
    	font-size: 1.8rem;
    	font-weight: var(--f-500);
    	text-transform: uppercase;
        background-color: transparent;
    	position: relative;

        &::before {
    		content: '';
    		display: block;
    		width: 2rem;
    		height: .2rem;
    		background-color: currentColor;
    		position: absolute;
    		top: 50%;
    		left: -2rem;
    		transform: translateY(-50%);
    	}

        &:hover,
        &:focus,
    	&:focus-within {
    		color: var(--c-accent);
    	}

    }



}// .form
