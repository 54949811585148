/*------------------------------------*\
    Box
    .box
\*------------------------------------*/

.box {}// .box










/*------------------------------------*\
    Title
    .box.box--title
\*------------------------------------*/

.box--title {

    max-width: 100rem;

    .title {

        font-size: 2rem;
        line-height: 1.4;
        font-weight: var(--f-500);
        color: var(--c-grey-medium);

    }// .title

    .title2 {

        font-size: 5rem;
        font-weight: var(--f-bold);
        line-height: 1.2;
        color: var(--c-grey-darker);

        @media #{$media-s} { font-size: 4rem; }

    }// .title2
    .title + .title2 { margin-top: 2rem; }

    .text {}// .text
    .title + .text { margin-top: 2rem; }
    .title2 + .text { margin-top: 4rem; }

    .policy { margin-top: 4rem; }

}// .box--title










/*------------------------------------*\
    404
    .box.box--404
\*------------------------------------*/

.box--404 {

    padding: $gutter;
    padding-top: calc( var(--h-header) + #{$gutter} );

    .title {

        font-size: 5rem;
        font-weight: var(--f-bold);
        line-height: 1.8;

    }// .title

    .text {

        font-size: 2rem;
        font-weight: var(--f-500);

    }// .text

    .main_menu {

        margin-top: $gutter;

        .menu {

            .menu-item {

                font-weight: var(--f-500);
                display: flex;

                &:not(:first-of-type) { margin-top: $gutter * .25; }

                & > a:hover,
                & > a:focus-within {
                    color: var(--c-accent);
                }

                a {

                    display: inline-flex;
                    align-items: center;

                    &::before {

                        display: inline-flex;
                        width: 2.5rem;
                        height: 2.5rem;
                        content: '';
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-image: url(../images/list.svg);
                        margin-right: 1rem;

                        // CSS filter generator to convert from black to target hex color
                        // https://codepen.io/sosuke/pen/Pjoqqp
                        // filter: invert(100%);
                    }

                }

            }// .menu-item

            & > .menu-item > a:hover::before,
            & > .menu-item > a:focus-within::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }

        }// .menu

    }// .main_menu

}// .box--404
