/*------------------------------------*\
    Post Type: Pagine ( page )
    General
    page.php
    *page_template_name*.php
    .page
    .page-template
\*------------------------------------*/

.page-template {



    .page--intro {

        .inner { padding: $gutter; }

        .image {

            width: 100%;
            height: 55rem;
            background-color: var(--c-grey-lighter);

            @media #{$media-m} { height: 45rem; }
            @media #{$media-s} { height: 40rem; }

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .image

        .container {}// .container

    }// .page--intro



}// .page-template










/*------------------------------------*\
    Page
    page.php
    .page-template-default
\*------------------------------------*/

.page-template-default {



    .top {

        height: 34rem;
        background-color: var(--c-grey-lighter);
        background-image: url(../images/texture-wall.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }// .top



    .page--intro {

        padding: $gutter;

    }// .page--intro



}// .page-template-default
