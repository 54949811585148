/*------------------------------------*\
    Post Type: Soluzioni ( solution )
    General
\*------------------------------------*/

.post-type-archive-solution,
.single-solution {

    .solution--intro .inner,
    .solution-archive .inner {
        padding: $gutter;
    }

    .solution--intro > .bg,
    .solution-archive > .bg {

        height: 28rem;
        background-color: var(--c-grey-light);
        background-image: url(../images/texture-wall.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }// .top

}




/*------------------------------------*\
    Post Type: Soluzioni ( solution )
    archive-solution.php
    .post-type-archive-solution {}
\*------------------------------------*/

.post-type-archive-solution {}// .post-type-archive-solution

.solution-archive {



    .items {

        margin-top: $gutter * 2;

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        row-gap: 14rem;

        @media #{$media-m} {
            flex-direction: column;
            row-gap: 8rem;
        }

        .item {

            --image: 38rem;
            max-width: 70rem;
            position: relative;
            padding-top: $gutter * 3;

            @media #{$media-bx} {
                --image: 32rem;
                max-width: 60rem;
            }
            @media #{$media-b} {
                --image: 30rem;
                max-width: 50rem;
            }
            @media #{$media-m} {
                --image: 38rem;
                max-width: 70rem;
            }
            @media #{$media-sm} { --image: 32rem; }
            @media #{$media-sx} {
                --image: 24rem;
                padding-top: 0;
            }

            .image {

                position: absolute;
                top: 0;
                left: 0;
                width: var(--image);
                height: var(--image);
                background-color: var(--c-white);

                display: flex;
                flex-direction: column;

                @media #{$media-sm} { left: 6rem; }
                @media #{$media-sx} {
                    width: 100%;
                    height: auto;
                    position: static;
                }

                .title {

                    font-size: 3.2rem;
                    line-height: 1.1;
                    font-weight: var(--f-bold);
                    text-align: center;
                    margin-top: 2rem;

                    .link {

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 5;
                        }

                        &:hover,
                        &:focus-within {
                            color: var(--c-accent);
                        }

                    }// .link

                }// .title

                .img {

                    display: block;
                    margin: auto;
                    margin-right: -2rem;

                    @media #{$media-sx} { margin: 2rem auto; }

                }// .img

            }// .image

            .content {

                height: 100%;
                min-height: 34rem;
                margin-left: $gutter * 2;
                background-color: var(--c-grey-lighter);

                display: flex;
                flex-direction: column;
                align-items: flex-end;
                // justify-content: flex-end;

                @media #{$media-sm} { margin-left: 0; }
                @media #{$media-sx} { min-height: initial; }

                .inner {

                    padding: 2rem;
                    max-width: 32rem;
                    margin-left: calc(var(--image) - 8rem);
                    margin-right: auto;

                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    @media #{$media-sm} {

                        padding: 4rem;
                        max-width: initial;
                        margin-top: calc(var(--image) - 12rem);
                        margin-left: 0;

                    }
                    @media #{$media-sx} {
                        padding: 2rem;
                        margin-top: 2rem;
                    }

                }// .inner

                .text {

                    font-weight: var(--f-500);
                    margin-left: 2rem;
                    margin-bottom: 2rem;

                }// .text

                .more {

                    margin-top: auto;

                }// .more

            }// .content

        }// .item

    }// .items

}// .solution-archive










/*------------------------------------*\
    Post Type: Soluzioni ( solution )
    single-solution.php
    .single-solution {}
\*------------------------------------*/

.single-solution {}// .single-solution

.solution-single {



    .solution--intro {

        // padding: $gutter;

        .container {

            display: flex;
            gap: $gutter * 2;

            @media #{$media-m} {
                flex-direction: column;
                gap: $gutter;
            }

        }// .container

        .content {

            flex-shrink: 0;
            max-width: 70rem;

            @media #{$media-b} { max-width: 50rem; }

            .text {}// .text

        }// .content

        .image {

            margin-top: $gutter * 1.5;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            position: relative;

            @media #{$media-m} {
                order: -1;
                margin-top: 0;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                max-height: 38rem;
                background-color: var(--c-grey-lighter);
                z-index: -1;
            }

            .img {

                display: block;
                padding: $gutter;
                max-width: 50rem;

                @media #{$media-bx} { max-width: 40rem; }
                @media #{$media-s} {
                    max-width: none;
                    width: 100%;
                }

            }// .img

        }// .image

    }// .solution--intro





    .solution--characteristics {

        padding: $gutter;

        .container {

            display: flex;
            align-items: center;
            gap: $gutter * 2;

            @media #{$media-m} {
                flex-direction: column;
                gap: $gutter;
                align-items: flex-start;
            }

        }

        .content {

            order: 1;
            max-width: 80rem;

            .title {
                font-size: 4rem;
                @media #{$media-m} { font-size: 3rem; }
            }

        }// .content

        .gallery {

            flex-shrink: 0;
            width: 60rem;
            height: 60rem;
            background-color: var(--c-grey-lighter);
            position: relative;
            overflow: hidden;

            @media #{$media-b} {
                width: 50rem;
                height: 50rem;
            }
            @media #{$media-s} {
                width: 40rem;
                height: 40rem;
            }
            @media #{$media-sx} {
                width: 100%;
                height: 30rem;
            }


            .slick-list,
            .slick-track,
            .slick-slide {
                width: 100%;
                height: 100%;
            }

            .slick-dots {

                position: absolute;
                bottom: 0;
                width: 100%;
                margin-top: 0;
                height: 4rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
    			gap: 1rem;
    			list-style: none;
    			margin: 0;

                li {

                    position: relative;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 2rem;
                    height: 2rem;
                    padding: .3rem;
                    border-radius: 50%;
                    border: .1rem solid var(--c-grey-lightest);
                    background-color: var(--c-white);

                    button {
                        background-color: transparent;
                        font-size: 0;
                        border: none;
                        border-radius: 50%;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        padding: 0;
                    }

                    &.slick-active button {
                        background-color: var(--c-accent);
                    }

                }

            }// .slick-dots

            .image {

                width: 100%;
                height: 100%;

                .link {

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    .img {

                        display: block;
                        width: 100%;
                        height: auto;
                        object-fit: cover;

                    }// .img

                }// .link

            }// .image

        }// .gallery


    }// .solution--characteristics





    .solution--versions {

        padding: $gutter;

        .title {

            font-size: 4rem;
            @media #{$media-m} { font-size: 3rem; }

        }// .title

        .items {

            margin-top: $gutter;
            display: flex;
            flex-direction: column;
            gap: $gutter;

        }// .items

        .item {

            display: flex;
            gap: $gutter;

            @media #{$media-sm} { flex-direction: column; }

            .image {

                flex-shrink: 0;
                background-color: var(--c-white);
                width: 30rem;
                height: 30rem;

                @media #{$media-sxx} {
                    width: 100%;
                    height: auto;
                    aspect-ratio: 1;
                }

                .link {

                    display: block;
                    width: 100%;
                    height: 100%;

                }// .link

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                }// .img

            }// .image

            .content {}// .content

            .name {

                text-transform: uppercase;
                font-size: 2.4rem;
                font-weight: var(--f-500);
                line-height: 1.2;

                @media #{$media-m} { font-size: 2rem; }

            }// .name

            .text {}// .text

        }// .item

    }// .solution--versions





    .solution--video {

        margin-top: $gutter;
        margin-bottom: $gutter;

        padding: $gutter;

        .title {

            font-size: 4rem;
            @media #{$media-m} { font-size: 3rem; }

        }// .title

        .video {

            margin-top: $gutter;
            width: 100%;
            min-height: 30rem;
            padding: $gutter;
            background-color: var(--c-white);

            .link {

                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: auto;

            }// .link

            .img {

                display: block;

            }// .img

        }// .video

    }// .solution--video





    .solution--download {

        padding: $gutter;

        details {

            padding: $gutter;
            background-color: var(--c-grey-lighter);

            position: relative;

            summary {

                display: flex;
                justify-content: space-between;

                &:hover {
                    cursor: pointer;
                }

                .title {

                    font-size: 2rem;
                    font-weight: var(--f-bold);
                    // line-height: 1.1rem;
                    text-transform: uppercase;

                }// .title

                .icon {

                    display: block;
                    width: 1.5rem;
                    height: auto;
                    fill: currentColor;

                }// .icon

            }// summary

            .content {

                // border-top: .1rem solid var(--c-grey-darker);
                margin-top: $gutter * .5;
                padding-top: $gutter * .5;

                .file {

                    padding-bottom: $gutter * .5;
                    border-bottom: .1rem dashed var(--c-grey-light);

                    &:not(:first-of-type) { margin-top: $gutter * .5; }

                    .link {

                        display: inline-flex;
                        align-items: center;
                        column-gap: $gutter * .5;
                        row-gap: $gutter * .25;

                        @media #{$media-s} { flex-wrap: wrap; }
                        @media #{$media-sx} { width: 100%; }

                    }// .link

                    .label {

                        font-weight: var(--f-500);
                        min-width: 20rem;

                        @media #{$media-s} {
                            width: 100%;
                            min-width: none;
                        }

                    }// .label

                    .icon {

                        width: 2.5rem;
                        height: 3rem;

                    }// .icon

                    .info {

                    }// .info

                }// .file

            }// .content

            &[open] {

                summary .icon { transform: rotate(180deg); }// .icon

            }

        }// details

    }// .solution--download



    .links {

        margin-top: $gutter;
        padding: $gutter;

        .container {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: $gutter;

        }

        .link {

            display: inline-flex;
            justify-content: center;
            align-items: center;

            margin-left: 2rem;
            min-width: 14rem;
            min-height: 4.6rem;
            border: .2rem solid currentColor;
            padding: .5rem 2rem;

            font-size: 1.4rem;
            font-weight: var(--f-500);
            text-transform: uppercase;

            position: relative;

            &::before {
                content: '';
                display: block;
                width: 2rem;
                height: .2rem;
                background-color: currentColor;
                position: absolute;
                top: 50%;
                left: -2rem;
                transform: translateY(-50%);
            }

            &:hover,
            &:focus-within {

                color: var(--c-accent);

            }

        }// .link

    }// .links





}// .solution-single
