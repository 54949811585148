/*------------------------------------*\
    Page Template: Homepage
    homepage.php
    .page-template-homepage
\*------------------------------------*/

.page-template-homepage {


    .home--section {

        margin-top: $gutter * 2;

    }// .vmc--section



    .home--intro {

        width: 100%;
        height: 100vh;
        height: 100dvh;
        min-height: 90rem;
        overflow: hidden;

        @media (max-height: 900px) { min-height: 85rem; }

        position: relative;

        .image {

            display: block;
            width: 100%;
            height: 100%;

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .image

        .inner {

            position: absolute;
            inset: 0;

            padding: $gutter;

            padding-top: calc(#{$h-header + $gutter});
            padding-bottom: $gutter * 2;



        }// .inner

        .container {

            height: 100%;
            display: flex;
            flex-direction: column;

            @media #{$media-sm} { justify-content: center; }

            .image--product {

                max-width: 50rem;
                margin-left: auto;

                @media #{$media-m-min} { margin-right: $gutter; }
                @media #{$media-sm} {
                    max-width: 30rem;
                    margin-top: $gutter;
                    margin-inline: auto;
                    background-color: hsla(0, 0%, 100%, .8);
                    padding: $gutter * .5;
                }

            }// .image--product

            .content {

                max-width: 70rem;
                color: var(--c-white);
                margin-top: auto;

                @media #{$media-m-min} { margin-left: $gutter; }
                @media #{$media-sm} {
                    max-width: 40rem;
                    text-align: center;
                    order: -1;
                    margin-inline: auto;
                }

                .title {

                    font-size: 6rem;
                    font-weight: var(--f-bold);
                    line-height: 1.1;
                    text-shadow: 0 .3rem .6rem rgba(0, 0, 0, 0.5);

                    @media #{$media-s} { font-size: 4rem; }

                }// .title

                .title2 {

                    font-size: 4rem;
                    font-weight: var(--f-300);
                    line-height: 1.1;
                    text-shadow: .2rem .3rem .2rem rgba(0, 0, 0, 0.6);

                    @media #{$media-s} { font-size: 3rem; }

                    span { font-weight: var(--f-500); }

                }// .title2

                .title + .title2 { margin-top: $gutter * .5; }

            }// .content

        }// .container

    }// .home--intro



    .home--info {

        .items {

            display: flex;
            flex-direction: column;
            gap: $gutter;

        }// .items

        .item {

            height: calc(54rem + #{$gutter*2});
            padding: $gutter;
            overflow: hidden;

            @media #{$media-sm} { height: auto; }

            .container {

                position: relative;
                display: flex;
                align-items: flex-start;
                height: 100%;

                @media #{$media-sm} { flex-wrap: wrap; }

            }// .container

            .image {

                width: 120rem;
                height: 54rem;
                position: absolute;
                z-index: 1;
                top: 0;

                @media #{$media-b} { width: 100%; }

                @media #{$media-sm} {
                    position: static;
                    height: 35rem;
                }

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }// .img

            }// .image

            .icons {

                flex-shrink: 0;
                color: var(--c-grey-dark);

                width: 14rem;
                height: 14rem;
                border: .4rem solid currentColor;
                position: relative;
                z-index: 3;
                padding: 3rem;
                display: flex;
                justify-content: center;
                align-items: center;

                margin-top: 9.5rem;

                @media #{$media-sm} {
                    margin-top: 0;
                    width: 7rem;
                    height: 7rem;
                    padding: 1.5rem;
                }

                &::before {

                    content: '';
                    display: block;
                    background-color: currentColor;
                    position: absolute;

                    width: .4rem;
                    height: 6rem;
                    top: -4rem;
                    left: 50%;
                    transform: translateX(-50%);

                    @media #{$media-sm} {
                        height: 4rem;
                        top: -3rem;
                    }

                }

                .icon {

                    display: block;
                    width: 100%;
                    height: 100%;
                    fill: currentColor;

                }// .icon

            }// .icons

            .content {

                position: relative;
                z-index: 2;
                max-width: 60rem;
                padding: $gutter;
                background-color: var(--c-white);

                @media #{$media-sm-min} {
                    min-height: 35rem;
                    margin-top: 9.5rem;
                }
                @media #{$media-sm} { margin-top: 0; }

                .title {

                    color: var(--c-grey-medium);
                    font-size: 4rem;
                    font-weight: var(--f-bold);
                    line-height: 1.1;

                    @media #{$media-sm} { font-size: 3rem; }

                }// .title

                .title + .text { margin-top: $gutter * .5; }

                .text {

                    line-height: 1.5;

                }// .text

                .more {}// .more

            }// .content

            // .item 3n+1 -> 1,4,7,…
            // &:nth-of-type(3n+1) {
            &:nth-of-type(odd) {

                .image { right: 0; }

                .icons {

                    // margin-top: 9.5rem;
                    //
                    // @media #{$media-sm} { margin-top: 0; }

                    // &:before {
                    //     width: .4rem;
                    //     height: 6rem;
                    //     top: -4rem;
                    //     left: 50%;
                    //     transform: translateX(-50%);
                    //
                    //     @media #{$media-sm} {
                    //         height: 4rem;
                    //         top: -3rem;
                    //     }
                    // }

                }

                // .content {
                //
                //     @media #{$media-sm-min} {
                //         min-height: 35rem;
                //         margin-top: 9.5rem;
                //     }
                //     @media #{$media-sm} { margin-top: 0; }
                // }

            }

            // .item 3n+2 -> 2,5,8,…
            // &:nth-of-type(3n+2) {
            &:nth-of-type(even) {

                background-color: var(--c-grey-lighter);

                padding-top: $gutter*2;
                padding-bottom: $gutter*2;

                @media #{$media-sm-min} { height: calc(54rem + #{$gutter*4} + #{$gutter*2}); }

                // .container {
                //     @media #{$media-sm-min} { flex-direction: column; }
                // }

                // .image {
                //     @media #{$media-sm-min} {
                //         left: 50%;
                //         transform: translateX(-50%);
                //     }
                // }

                .icons {

                    @media #{$media-sm-min} {

                        order: 1;

                        // margin-left: auto;
                        // margin-right: $gutter;
                        //
                        // &::before {
                        //     width: 6rem;
                        //     height: .4rem;
                        //     top: 50%;
                        //     right: -4rem;
                        //     transform: translateY(-50%);
                        // }
                    }

                    // @media #{$media-sm} {
                    //     &::before {
                    //         width: .4rem;
                    //         height: 4rem;
                    //         top: -3rem;
                    //         left: 50%;
                    //         transform: translateX(-50%);
                    //     }
                    // }

                }

                .content {
                    @media #{$media-sm-min} {
                        // margin-top: auto;
                        margin-left: auto;
                    }
                }

            }

            // .item 3n -> 3,6,9,…
            // &:nth-of-type(3n) {
            //
            //     .container {
            //         @media #{$media-sm-min} { justify-content: flex-end; }
            //     }
            //
            //     .image { left: 0; }
            //
            //     .icons {
            //
            //         @media #{$media-sm-min} {
            //             order: 1;
            //             margin-left: -5.5rem;
            //         }
            //         @media #{$media-sm} { margin-left: 0; }
            //
            //         &::before {
            //             width: .4rem;
            //             height: 6rem;
            //             top: -4rem;
            //             left: 50%;
            //             transform: translateX(-50%);
            //
            //             @media #{$media-sm} {
            //                 height: 4rem;
            //                 top: -3rem;
            //             }
            //         }
            //     }
            //
            //     .content {
            //
            //         @media #{$media-sm-min} {
            //             min-height: 35rem;
            //             margin-top: 9.5rem;
            //         }
            //
            //         @media #{$media-sm} {
            //             order: 2;
            //             margin-top: 0;
            //         }
            //     }
            //
            // }

        }// .item

    }// .home--info



    .home--cta {

        padding: $gutter;

        .cta {

            max-width: 100rem;
            margin: auto;
            padding: $gutter * 2;
            border: .4rem solid currentColor;
            background-color: var(--c-grey-lighter);

            text-align: center;

            .title {

                font-size: 4rem;
                font-weight: var(--f-bold);
                line-height: 1.1;

                @media #{$media-sm} { font-size: 3rem; }

            }// .title

            .more {}// .more

        }// .cta

    }// .home--cta



}// .page-template-homepage
