/*------------------------------------*\
    Header
   #header.header
\*------------------------------------*/

.header {

    position: fixed;
    z-index: 10;

    width: 100%;
    height: var(--h-header);

    background-color: transparent;

}// .header










/*------------------------------------*\
    Header content
   .header__content
\*------------------------------------*/

.header__content {

    position: relative;
    background-color: hsla(0, 0%, 100%, .9);
    height: 100%;

    padding-left: $gutter;
    padding-right: $gutter;

    .container {

        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 100%;

    }



    .brand {

        width: 25rem;
        height: auto;

        @media #{$media-s} { width: 20rem; }
        @media #{$media-sxx} { width: 15rem; }

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }// .brand


    .header_menu {

        margin-left: auto;

        display: flex;
        align-items: center;

        @media #{$media-m} { display: none; }

        &::after {
            content: '';
            display: block;
            width: .1rem;
            height: 3rem;
            background-color: var(--c-grey-light);
            margin-left: $gutter * .5;
        }

        .menu {

            display: flex;
            gap: $gutter * .5;

            .menu-item {

                font-weight: var(--f-500);

                &:hover,
                &:focus-within {
                    color: var(--c-accent);
                }

                &.current-menu-item > a,
                &.current-menu-parent > a,
                &.current-menu-ancestor > a {
                    color: var(--c-accent);
                }

                @at-root body.single-solution #{&}.menu-item-object-solution > a { color: var(--c-accent); }
                @at-root body.single-faq #{&}.menu-item-object-faq > a { color: var(--c-accent); }

            }// .menu-item

        }// .menu

    }// .header_menu



    .language__nav {

        margin-left: $gutter * .5;

    }// .language__nav



    .nav__open {

        margin-left: $gutter * .5;

        border: none;
        padding: 0;
        background-color: transparent;

        width: 3rem;
        height: 3rem;

        .icon {

            display: block;
            width: 100%;
            height: 100%;

            &:hover,
            &:focus-within {
                fill: var(--c-accent);
            }

        }

    }// .nav__open



}// .header__content










/*------------------------------------*\
    Main Navigation
   nav.main__nav
\*------------------------------------*/

.main__nav {

    touch-action: none;
    background-color: var(--c-bg);

    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;

    will-change: transform, opacity, visibility;
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease, opacity 0.5s ease, visibility 0.5s;

    :focus { outline: .1rem dashed var(--c-accent); outline-offset: .2rem; }



    .content {

        max-width: var(--w-max);
        padding: $gutter;
        margin: auto;

        display: flex;
        align-items: center;

    }// .content



    .brand {

        width: 20rem;
        height: auto;

        @media #{$media-sxx} { width: 15rem; }

        .link,
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }

    }// .brand



    .nav__close {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 6rem;
        height: 6rem;
        position: relative;
        order: 1;
        flex-shrink: 0;
        margin-left: auto;
        z-index: 5;
        border: none;

        @media #{$media-s} {
            width: 4rem;
            height: 4rem;
        }
        &:hover,
        &:focus-within {
            background-color: var(--c-accent);
            color: var(--c-white);
            fill: currentColor;
        }

        .icon {

            display: block;
            width: 3rem;
            height: 3rem;

            @media #{$media-s} {
                width: 2rem;
                height: 2rem;
            }

        }// .icon

    }// nav_close



    /*------------------------------------*\
        Main Menu -> main_menu
        Main Navigation
        nav.main_menu
    \*------------------------------------*/

    .main_menu {

        position: relative;
        z-index: 2;

        max-width: var(--w-max);
        padding: $gutter;
        padding-top: 0;
        margin: auto;

    }// .main_menu

    .menu {

        .menu-item {

            font-weight: var(--f-500);
            display: flex;

            &:not(:first-of-type) { margin-top: $gutter * .25; }

            & > a:hover,
            & > a:focus-within {
                color: var(--c-accent);
            }

            &.current-menu-item > a,
            &.current-menu-parent > a,
            &.current-menu-ancestor > a {
                color: var(--c-accent);
            }

            @at-root body.single-solution #{&}.menu-item-object-solution > a { color: var(--c-accent); }
            @at-root body.single-faq #{&}.menu-item-object-faq > a { color: var(--c-accent); }

            a {

                display: inline-flex;
                align-items: center;

                &::before {

                    display: inline-flex;
                    width: 2.5rem;
                    height: 2.5rem;
                    content: '';
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    // background-image: url(../images/list.svg);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.11 18.24'%3E%3Cpath d='M8.87,0V8.12H0v2H8.87v8.12H27.11V0ZM25.11,16.24H10.87V10.12h3.48v-2H10.87V2H25.11Z' /%3E%3C/svg%3E%0A");
                    margin-right: 1rem;

                    // CSS filter generator to convert from black to target hex color
                    // https://codepen.io/sosuke/pen/Pjoqqp
                    // filter: invert(100%);
                }

            }

        }// .menu-item

        & > .menu-item > a:hover::before,
        & > .menu-item > a:focus-within::before,
        & > .menu-item.current-menu-item > a::before,
        & > .menu-item.current-menu-parent > a::before,
        & > .menu-item.current-menu-ancestor > a::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }

        @at-root body.single-solution #{&} > .menu-item-object-solution > a::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }
        @at-root body.single-faq #{&} > .menu-item-object-faq > a::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }

    }// .menu

    &.open,
    &.toggled-on {

        transform: translateX(0%);
        opacity: 1;
        visibility: visible;

    }

}// .main__nav
