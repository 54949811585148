/*------------------------------------*\
    Page Template: Vantaggi
    vantaggi.php
    .page-template-vantaggi
\*------------------------------------*/

.page-template-vantaggi {



    .vantaggi--section {

        margin-top: $gutter * 2;

    }// .vantaggi--section


    .vantaggi--benefits {

        padding: $gutter;

        .items {

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $gutter*2;

            @media #{$media-sm} { grid-template-columns: repeat(1, 1fr); }

            .item {

                width: 100%;
                max-width: 75rem;

                display: flex;

                @media #{$media-b} { flex-direction: column; }

                .image {

                    flex-shrink: 0;
                    width: 25rem;
                    height: 25rem;
                    aspect-ratio: 1;
                    position: relative;

                    @media #{$media-b-min} { margin-right: $gutter; }
                    @media #{$media-b} { margin-bottom: $gutter * .5; }

                    @media #{$media-sxx} {
                        width: 100%;
                        height: auto;
                        aspect-ratio: 1;
                    }

                    &::before {
            			content: '';
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 8rem;
            			height: 6.2rem;
            			background-repeat: no-repeat;
            			background-image: url(../images/list.svg);
                        margin-left: -2.6rem;
            		}

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                    }// .img

                }// .image

                .content {

                    width: 100%;

                }// .content

                .title {

                    font-size: 3rem;
                    line-height: 1.25;
                    font-weight: var(--f-500);
                    margin-bottom: $gutter;

                    @media #{$media-b} { margin-bottom: $gutter * .5; }
                    @media #{$media-s} { font-size: 2.4rem; }

                }// .title

                .text {}// .text

            }// .item

        }// .items

    }// .vantaggi--benefits



    .vantaggi--reasons {

        padding: $gutter;

        .title {

            font-size: 3rem;
            line-height: 1.25;
            font-weight: var(--f-500);

            @media #{$media-s} { font-size: 2.4rem; }

        }// .title

        .text--reasons {

            margin-top: $gutter;

            ul {

        		list-style-type: none;
                margin: 0;

                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: $gutter $gutter*2;

                @media #{$media-m} {
                    grid-template-columns: repeat(1, 1fr);
                }

                li {

                    max-width: 70rem;
            		display: flex;
            		align-items: center;

                    font-size: 2.2rem;
                    line-height: 1.25;
                    color: var(--c-grey-medium);

                    @media #{$media-s} { font-size: 1.8rem; }

            		&::before {

            			margin-top: .5rem;
            			flex-shrink: 0;
            			content: '';
            			width: 8rem;
            			height: 6.2rem;
            			background-repeat: no-repeat;
            			background-image: url(../images/list-check.svg);
            			margin-right: $gutter;

                        @media #{$media-m} {
                            width: 6rem;
                			height: 4.7rem;
                        }
                        @media #{$media-s} {
                            width: 4rem;
                			height: 3.1rem;
                            margin-right: $gutter * .5;
                        }

            		}

                }

        	}

        }// .text--reasons

    }// .vantaggi--reasons



}// .page-template-vantaggi
