/*------------------------------------*\
    Page Template: Umidità
    umidita.php
    .page-template-umidita
\*------------------------------------*/

.page-template-umidita {



    .page--intro {

        .container {

            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: $gutter*2;

            @media #{$media-b} { grid-template-columns: 1fr 1fr; }
            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: $gutter;
            }

        }// .container

        .gallery {

            width: 100%;
            // height: 100%;
            max-height: 50rem;
            aspect-ratio: 1;
            display: flex;
    		flex-wrap: wrap;

            @media #{$media-m-min} {
                order: 1;
                max-width: 58rem;
            }
            @media #{$media-m} { width: 60rem; }
            @media #{$media-s} { width: 30rem; }
            @media #{$media-sx} { width: 25rem; }

            .slick-list,
            .slick-track,
            .slick-slide {
                width: 100%;
                height: 100%;
            }

            .slick-dots {

                width: 100%;
                margin-top: 0;
                height: 4rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
    			gap: 1rem;
    			list-style: none;
    			margin: 0;
                margin-top: -4rem;

                li {

                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    width: 1.8rem;
                    height: 1.8rem;
                    border-radius: 50%;

                    cursor: pointer;
                    background-color: var(--c-grey-lighter);
                    border: .1rem solid var(--c-grey-light);

                    &.slick-active {
                        background-color: var(--c-accent);
                        border-color: var(--c-accent);
                    }

                }

                button {
                    display: none;
                }

            }// .slick-dots

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .gallery

    }// .page--intro



    .umidita--section {}// .umidita--section



    .umidita--block {

        padding: $gutter;

        .container {

            // display: flex;
            // flex-direction: column;
            // gap: $gutter;

            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: $gutter;

        }

        .image {

            max-width: 80rem;

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .image

        .content {



        }// .content

    }// .umidita--block

    .umidita--block + .umidita--block { margin-top: $gutter; }

    .umidita--block2 {

        .container {

            grid-template-columns: 1fr 2fr;
            grid-gap: $gutter*2;

            @media #{$media-b} { grid-template-columns: 1fr 1fr; }
            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: $gutter;
            }

        }

        .image {

            max-width: 60rem;

            // @media #{$media-m-min} { order: 1; }

            @media #{$media-m} { order: 1; }

        }

    }// .umidita--block



}// .page-template-umidita
