/*------------------------------------*\
    Post Type: Faq ( faq )
    General
\*------------------------------------*/

.post-type-archive-faq,
.single-faq {

    .faq--intro .inner,
    .faq-archive .inner {
        padding: $gutter;
    }

    .faq--intro > .bg,
    .faq-archive > .bg {

        height: 28rem;
        background-color: var(--c-grey-light);
        background-image: url(../images/texture-wall.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }// .top

}




/*------------------------------------*\
    Post Type: Faq ( faq )
    archive-faq.php
    .post-type-archive-faq {}
\*------------------------------------*/

.post-type-archive-faq {}// .post-type-archive-faq

.faq-archive {



    .items {

        margin-top: $gutter * 2;

        display: grid;
        grid-gap: $gutter;
        grid-template-columns: repeat(2, 1fr);

        @media #{$media-m} { grid-template-columns: repeat(1, 1fr); }

        .item {

            position: relative;
            display: flex;
            align-items: center;
            gap: $gutter * .5;
            padding: $gutter * .5;

            @media #{$media-b} { flex-direction: column; align-items: flex-start; }
            @media #{$media-m} { flex-direction: row; align-items: center; }
            @media #{$media-s} { flex-direction: column; align-items: flex-start; }

            .image {

                flex-shrink: 0;
                width: 24rem;
                height: 12rem;

                .img {

                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                }// .img

            }// .image

            .content {

                .title {

                    font-size: 2.6rem;
                    line-height: 1.1;
                    font-weight: var(--f-bold);

                    .link {

                        text-decoration: underline;

                        &::before {
                            content: '';
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: 5;
                        }

                        &:hover,
                        &:focus-within {
                            color: var(--c-accent);
                        }

                    }// .link

                }// .title

                .title + .text { margin-top: 1rem; }

                .text {

                    font-weight: var(--f-500);

                }// .text

            }// .content

            @media #{$media-m-min} {

                &:nth-of-type(4n+1),
                &:nth-of-type(4n+2) { background-color: var(--c-grey-lighter2); }

                &:nth-of-type(4n+3),
                &:nth-of-type(4n) { background-color: var(--c-grey-lighter); }

            }
            @media #{$media-m} {

                &:nth-of-type(odd) { background-color: var(--c-grey-lighter2); }
                &:nth-of-type(even) { background-color: var(--c-grey-lighter); }

            }


        }// .item

    }// .items

}// .faq-archive










/*------------------------------------*\
    Post Type: Soluzioni ( faq )
    single-faq.php
    .single-faq {}
\*------------------------------------*/

.single-faq {}// .single-faq

.faq-single {



    .faq--intro {

        // padding: $gutter;

        .container {

            display: flex;
            gap: $gutter * 2;

            @media #{$media-m} {
                flex-direction: column;
                gap: $gutter;
            }

        }// .container

        .content {

            flex-shrink: 0;
            max-width: 70rem;

            @media #{$media-b} { max-width: 50rem; }

            .text {}// .text

        }// .content

        .image {

            margin-top: $gutter * 1.5;
            width: 100%;

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .image

    }// .faq--intro



    .links {

        margin-top: $gutter;
        padding: $gutter;

        .container {

            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: $gutter;

        }

        .link {

            display: inline-flex;
            justify-content: center;
            align-items: center;

            margin-left: 2rem;
            min-width: 14rem;
            min-height: 4.6rem;
            border: .2rem solid currentColor;
            padding: .5rem 2rem;

            font-size: 1.4rem;
            font-weight: var(--f-500);
            text-transform: uppercase;

            position: relative;

            &::before {
                content: '';
                display: block;
                width: 2rem;
                height: .2rem;
                background-color: currentColor;
                position: absolute;
                top: 50%;
                left: -2rem;
                transform: translateY(-50%);
            }

            &:hover,
            &:focus-within {

                color: var(--c-accent);

            }

        }// .link

    }// .links





}// .faq-single
