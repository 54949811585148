/*------------------------------------*\
    Footer
   #footer.footer
\*------------------------------------*/

.footer {



    .footer__content {

        padding: $gutter*2 $gutter;

        .container {

            display: flex;
            align-items: center;
            gap: $gutter;

            @media #{$media-m} {
                flex-direction: column;
                align-items: flex-start;
            }

        }



        .footer__company {

            max-width: 56rem;

            .name {

                color: var(--c-grey-dark);
                font-weight: var(--f-500);

            }// .name

            .address {

                margin-top: $gutter * .5;
                color: var(--c-grey-light);

            }// .address

            .legal {

                margin-top: $gutter * .5;
                color: var(--c-grey-light);

            }// .legal

        }// .footer__company



        .footer__menu {

            flex-shrink: 0;

            .title {

                font-size: 2rem;
                line-height: 1.4;
                font-weight: var(--f-500);
                color: var(--c-grey-medium);

            }// .title

            .menu {

                .menu-item {

                    font-weight: var(--f-500);
                    display: flex;
                    margin-top: $gutter * .25;

                    // &:not(:first-of-type) { margin-top: $gutter * .25; }

                    & > a:hover,
                    & > a:focus-within {
                        color: var(--c-accent);
                    }

                    // &.current-menu-item > a,
                    // &.current-menu-parent > a,
                    // &.current-menu-ancestor > a {
                    //     color: var(--c-accent);
                    // }
                    //
                    // @at-root body.single-solution #{&}.menu-item-object-solution > a { color: var(--c-accent); }
                    // @at-root body.single-faq #{&}.menu-item-object-faq > a { color: var(--c-accent); }

                    a {

                        display: inline-flex;
                        align-items: center;

                        &::before {

                            display: inline-flex;
                            width: 2.5rem;
                            height: 2.5rem;
                            content: '';
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                            // background-image: url(../images/list.svg);
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27.11 18.24'%3E%3Cpath d='M8.87,0V8.12H0v2H8.87v8.12H27.11V0ZM25.11,16.24H10.87V10.12h3.48v-2H10.87V2H25.11Z' /%3E%3C/svg%3E%0A");
                            margin-right: 1rem;

                            // CSS filter generator to convert from black to target hex color
                            // https://codepen.io/sosuke/pen/Pjoqqp
                            // filter: invert(100%);
                        }

                    }

                }// .menu-item

                & > .menu-item > a:hover::before,
                & > .menu-item > a:focus-within::before,
                /*& > .menu-item.current-menu-item > a::before,
                & > .menu-item.current-menu-parent > a::before,
                & > .menu-item.current-menu-ancestor > a::before*/ { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }

                // @at-root body.single-solution #{&} > .menu-item-object-solution > a::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }
                // @at-root body.single-faq #{&} > .menu-item-object-faq > a::before { filter: invert(41%) sepia(47%) saturate(684%) hue-rotate(355deg) brightness(94%) contrast(104%); }

            }// .menu

        }// .footer__menu



        .footer__social {

            margin-left: auto;

            display: flex;
            gap: $gutter;

            @media #{$media-m} { margin-left: 0; gap: $gutter * .5; }
            @media #{$media-sx} { flex-wrap: wrap; }

            .link {

                display: block;
                width: 5rem;
                height: 5rem;

                color: var(--c-grey-light);

                &:hover,
                &:focus-within {
                    color: var(--c-accent);
                }

                .icon { fill: currentColor; }

            }// .link

        }// .footer__social



    }// .footer__content



    .footer__policy {

        padding: $gutter;
        font-size: 1.4rem;

        background-color: var(--c-grey-darker);
        color: var(--c-grey-lighest);

        .container {

            max-width: var(--w-max);
            margin: auto;

            display: flex;
            gap: $gutter;

            @media #{$media-m} { flex-direction: column; }

        }


        .legal {}// .legal


        .items {

            margin-left: auto;
            flex-shrink: 0;

            display: flex;
            gap: $gutter * .5;

            @media #{$media-m} { margin-left: 0; }
            @media #{$media-sx} { flex-direction: column; }

            .item {

                font-size: 1.4rem;
                text-transform: uppercase;
                display: flex;
                align-items: center;

                &::before {
                    content: '';
                    display: block;
                    width: .1rem;
                    height: 1.8rem;
                    background-color: var(--c-grey-lighest);
                    margin-right: $gutter * .5
                }

                &:first-of-type::before {

                    content: none;
                    @media #{$media-sx} { content: ''; }

                }

                a:hover,
                a:focus-within {
                    color: var(--c-accent);
                }

            }// .item

        }// .items


    }// .footer__policy



}// .footer
