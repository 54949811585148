/*------------------------------------*\
   layout
\*------------------------------------*/

html,
body {
    height: 100%;
}

body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

    #header,
    #footer {
        flex: none;
    }

    #header {}

    #main {

        -webkit-box-flex: 1;
        -webkit-flex: 1 0 auto;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        position: relative;
        z-index: 1;

        :target { scroll-margin-block: calc(var(--h-header) + 4rem); }

    }

    #footer {}

    .container {

        margin-right: auto;
        margin-left: auto;
        max-width: var(--w-max);

    }
