/*------------------------------------*\
    Page Template: Referenze
    referenze.php
    .page-template-referenze
\*------------------------------------*/

.page-template-referenze {



    .page--intro {

        .bg {

            height: 28rem;
            background-color: var(--c-grey-light);
            background-image: url(../images/texture-wall.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

        }// .bg


        .items {

            margin-top: $gutter * 2;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $gutter*2;

            @media #{$media-m} {
                grid-gap: $gutter;
            }

            @media #{$media-s} {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: $gutter;
            }

            .item {

                --image: 36rem;
                max-width: 70rem;
                position: relative;
                display: flex;

                @media #{$media-bx} {
                    --image: 34rem;
                }
                @media #{$media-b-bx} {
                    --image: 30rem;
                }
                @media #{$media-b} {
                    --image: 28rem;
                    flex-direction: column;
                }
                @media #{$media-sx} {}

                .image--reference {

                    flex-shrink: 0;
                    margin-top: auto;
                    width: var(--image);
                    height: var(--image);
                    background-color: var(--c-white);

                    @media #{$media-sx} {
                        width: 100%;
                        height: auto;
                        aspect-ratio: 1;
                    }

                    .img {

                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;

                    }// .img

                }// .image--reference

                .content {


                    width: 100%;
                    background-color: var(--c-grey-lighter);

                    @media #{$media-b-min} { margin-top: $gutter; }

                    .inner {

                        max-width: 36rem;

                        .product {

                            .name {

                                font-size: 3rem;
                                line-height: 1.1;
                                font-weight: var(--f-bold);

                            }// .name

                            .name + .text { margin-top: 2rem; }

                            .text {

                                line-height: 1.2;
                                font-weight: var(--f-500);

                            }// .text

                            .image--product {

                                margin-top: 2rem;
                                max-width: 20rem;

                                .img {

                                    display: block;
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;

                                }// .img

                            }// .image--product

                        }// .product

                        .reference {

                            color: var(--c-grey-medium);
                            background-color: var(--c-grey-lighter2);
                            padding: 1rem;
                            text-align: center;
                            max-width: 26rem;
                            position: relative;
                            margin-top: 2rem;
                            margin-left: -4rem;
                            margin-bottom: -4rem;

                            @media #{$media-b-min} {
                                margin-left: -8rem;
                            }

                            &::before {
                                content: '';
                                display: block;
                                position: absolute;
                                top: -2rem;
                                left: 1rem;
                                width: 2.5rem;
                    			height: 3.5rem;
                    			background-repeat: no-repeat;
                    			background-image: url(../images/icon-location.svg);
                                filter: invert(100%);
                            }

                            .name {

                                font-weight: var(--f-500);

                            }// .name

                            .text {}// .text

                        }// .reference

                    }// .inner

                }// .content

            }// .item

        }// .items



    }// .page--intro



}// .page-template-referenze
