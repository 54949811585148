@media screen and (prefers-reduced-motion: no-preference) {

	html,
	body {
		scroll-behavior: smooth;
	}

}

html {
	font-size: 62.5%;
}

	body {

		color: var(--c-fg);
		background-color: var(--c-bg);

		font-family: var(--f-text);
		font-weight: var(--f-normal);
		font-size: 1.6rem;
		line-height: 1.8;
        -webkit-text-size-adjust: 100%;

		// &.main__nav-opened {
		// 	overflow: hidden;
		// 	height: 100%;
		//
		// 	/* Hide scrollbar for Chrome, Safari and Opera */
		// 	&::-webkit-scrollbar {
		// 		display: none;
		// 	}
		//
		// 	-ms-overflow-style: none;  /* IE and Edge */
		// 	scrollbar-width: none;  /* Firefox */
		//
		// 	 // position: fixed;
		//
		// }// &.main__nav-opened

	}
	// @-moz-document url-prefix() {
    // 	html.main__nav-opened,body.main__nav-opened{ overflow: hidden; }
	// }

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: var(--f-title);
		}

		p {}

		strong {
			font-weight: var(--f-bold);
		}

		a {
			text-decoration: none;
			color: currentColor;

			&:hover {}

		}










/*------------------------------------*\
   general
\*------------------------------------*/

::selection {
	background-color: var(--c-fg);
	color: var(--c-bg);
}

:focus { outline: .1rem dashed var(--c-accent); outline-offset: .2rem; }

.none { display: none; }

.hidden { visibility: hidden; }

.uppercase { text-transform: uppercase; }

.anchor {
    display: block;
    position: relative;
    top: calc(var(--h-header) * -1);
    visibility: hidden;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}





/*------------------------------------*\
   Text
\*------------------------------------*/

.text {

	a {
		color: currentColor;
		text-decoration: underline;
		text-decoration-skip-ink: auto;
	}

	ul,
	ol {
		margin: $gutter * .5;
	}

	ol { list-style-type: decimal; }
	ul { list-style-type: disc; }
	li {}

	p + p {
		margin-top: $gutter * .5;
	}

	strong { font-weight: var(--f-bold); }
	em { font-style: italic; }

}// .text

.text--list {

	ul {
		list-style-type: none;
		// margin-left: 3.6rem;
	}
	ul li {

		// display: flex;
		display: block;
		padding-left: 2rem;
		// align-items: center;

		&::before {
			margin-top: .5rem;
			// flex-shrink: 0;
			display: inline-block;
			content: '';
			width: 2.6rem;
			height: 1.8rem;
			background-repeat: no-repeat;
			background-image: url(../images/list.svg);
			// margin-right: 1rem;
			// margin-left: -2rem;
			margin-right: 1.4rem;
			margin-left: -4rem;
		}

		&:not(:first-of-type) {
			margin-top: 1rem;
		}

		// modified viewBox in "0 -5 27.11 22.24" from "0 0 27.11 18.24"
		// list-style-image: url(../images/list.svg);
		//
		// &::marker{
		//    font-size: 3.6em;
		//    line-height: 0.1;
		// }

	}

}// .text--list



#iub-pp-container { background-color: var(--c-white); }



/*------------------------------------*\
   Link more
   .more
\*------------------------------------*/

.more {

	display: inline-flex;
	justify-content: center;
	align-items: center;

	margin-left: 2rem;
	margin-top: $gutter;
	min-width: 14rem;
	min-height: 4.6rem;
	border: .2rem solid currentColor;
	padding-left: 2rem;
	padding-right: 2rem;

	font-size: 1.4rem;
	font-weight: var(--f-500);
	text-transform: uppercase;

	position: relative;

	&::before {
		content: '';
		display: block;
		width: 2rem;
		height: .2rem;
		background-color: currentColor;
		position: absolute;
		top: 50%;
		left: -2rem;
		transform: translateY(-50%);
	}

	&:hover,
	&:focus-within {

		color: var(--c-accent);

	}

}// .more
