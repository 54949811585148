// @function hsl($h, $s, $l) {
//     @return unquote('hsl(#{$h}, #{$s}, #{$l})');
// }
// @function hsla($var, $a) {
//     @return unquote('hsla(#{$var}, #{$a})');
// }
@function hsla($h, $s, $l, $a) {
  @return unquote('hsla(#{$h}, #{$s}, #{$l}, #{$a})');
}




// Responsive Media Query ( html-font-size = 62.5% = 1rem = 10px )
$media-u:               "(min-width: 1600px)";      // media query > 1600     // container 1570
$media-bx:              "(max-width: 1600px)";      // media query < 1600     // container 1170
$media-b-bx:            "(max-width: 1400px)";      // media query < 1600     // container 1170
$media-b:               "(max-width: 1280px)";      // media query < 1280     // container 97
$media-m:               "(max-width: 1080px)";      // media query < 1080     // container 74
$media-sm:              "(max-width: 860px)";       // media query < 860      // container 60
$media-s:               "(max-width: 640px)";       // media query < 640      // container 46
$media-sx:              "(max-width: 480px)";       // media query < 480      // container 100%
$media-sxx:             "(max-width: 360px)";       // media query < 360      // container 100%

$media-bx-min:          "(min-width: 1601px)";      // media query > 1600
$media-b-min:           "(min-width: 1281px)";      // media query > 1280
$media-m-min:           "(min-width: 1081px)";      // media query > 1040
$media-sm-min:          "(min-width: 861px)";      // media query > 860
$media-s-min:           "(min-width: 641px)";       // media query > 640

$media-motion:          "screen and (prefers-reduced-motion: no-preference)";



// Width
$w-media-u:             157rem;
$w-media-bx:            117rem;
$w-media-b:             97rem;
$w-media-m:             74rem;
$w-media-sm:            60rem;
$w-media-s:             46rem;
$w-media-sx:            100%;

// Height
$h-header:              16rem;



// Gutter
$gutter:                4rem;





// Colors
// Color Names -> http://chir.ag/projects/name-that-color/
/* Used colors

#050910 // hsl(218, 52%, 4%)
#2f2f2f // hsl(0, 0%, 18%)
#494949 // hsl(0, 0%, 29%)
#6f6f6f // hsl(0, 0%, 44%)
#e8e5e4 // hsl(15, 8%, 90%)
#d1c9c7 // hsl(12, 10%, 80%)
#f4f4f4 // hsl(0, 0%, 96%)

#a16515 // hsl(34, 77%, 36%)

#000000 // hsl(0, 0%, 0%)
#ffffff // hsl(0, 0%, 100%)

#cc0000; // hsl(0, 100%, 40%)

*/

:root {

    --c-black:              #000;
    --c-white:              #fff;

    --c-grey-darker:        #050910;
    --c-grey-dark:          #2f2f2f;
    --c-grey-medium:        #494949;
    --c-grey-light:         #6f6f6f;
    --c-grey-lighter:       #e8e5e4;
    --c-grey-lighter2:      #d1c9c7;
    --c-grey-lighest:       #f4f4f4;

    --c-bg:                 var(--c-grey-lighest);
    --c-fg:                 var(--c-grey-darker);

    --c-accent:             #a16515;

    --c-link:               var(--c-accent);
    --c-link-hover:         var(--c-accent);

    --c-error:              #c00;

    --c-modal:              var(--c-black);



    // height
    --h-header:             16rem;

    // width
    --w-max:                160rem;

    // gutter
    --gutter:               4rem;

    // font
    --f-title:              "Roboto", sans-serif;
    --f-text:               "Roboto", sans-serif;

    --f-normal:             normal; // 400
    --f-bold:               bold; // 700

    --f-300:                300;
    --f-400:                var(--f-normal);
    --f-500:                500;
    --f-700:                var(--f-bold);

}

// Fonts
// Roboto:wght@300;400;500;700 -> loaded via local Font

// $f-family-text:         "Roboto", sans-serif;
// $f-family-title:        "Roboto", sans-serif;
//
// $f-weight-300:          300;
// $f-weight-400:          400;
// $f-weight-500:          500;
// $f-weight-700:          700;
