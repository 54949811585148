/*------------------------------------*\
    Page Template: VMC
    vmc.php
    .page-template-vmc
\*------------------------------------*/

.page-template-vmc {



    .page--intro {

        .container {

            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: $gutter*2;

            @media #{$media-b} { grid-template-columns: 1fr 1fr; }
            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: $gutter;
            }

        }// .container

        .gallery {

            width: 100%;
            // height: 100%;
            max-height: 50rem;
            aspect-ratio: 1;
            display: flex;
    		flex-wrap: wrap;

            @media #{$media-m-min} {
                order: 1;
                max-width: 58rem;
            }
            @media #{$media-m} { width: 60rem; }
            @media #{$media-s} { width: 30rem; }
            @media #{$media-sx} { width: 25rem; }

            .slick-list,
            .slick-track,
            .slick-slide {
                width: 100%;
                height: 100%;
            }

            .slick-dots {

                width: 100%;
                margin-top: 0;
                height: 4rem;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
    			gap: 1rem;
    			list-style: none;
    			margin: 0;
                margin-top: -4rem;

                li {

                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    width: 1.8rem;
                    height: 1.8rem;
                    border-radius: 50%;

                    cursor: pointer;
                    background-color: var(--c-grey-lighter);
                    border: .1rem solid var(--c-grey-light);

                    &.slick-active {
                        background-color: var(--c-accent);
                        border-color: var(--c-accent);
                    }

                }

                button {
                    display: none;
                }

            }// .slick-dots

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .gallery

    }// .page--intro



    .vmc--section {

        margin-top: $gutter * 2;

    }// .vmc--section



    .vmc--items {

        padding: $gutter;

        .item {

            &:not(:first-of-type) {

                @media #{$media-m-min} { margin-top: $gutter * 2; }
                @media #{$media-m} { margin-top: $gutter * 4; }

            }

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: $gutter*2;

            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: $gutter;
            }

            .image {

                .img {

                    display: block;

                }// .img

            }// .image

            .content {

                .title {

                    font-size: 4rem;
                    font-weight: var(--f-bold);
                    line-height: 1.1;

                    @media #{$media-sm} { font-size: 3rem; }

                }// .title

                .title + .text { margin-top: $gutter; }

            }// .content

            &:nth-of-type(odd){

                .image {

                    @media #{$media-m-min} { order: 1; }

                }// .image

            }

        }// .item

    }// .vmc--items



}// .page-template-vmc
