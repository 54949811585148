/*------------------------------------*\
    Page Template: Contatti
    contatti.php
    .page-template-contatti
\*------------------------------------*/

.page-template-contatti {



    .page--intro {

        .image {

            height: 35rem;
            background-color: var(--c-grey-light);
            background-image: url(../images/texture-wall.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;

        }// .image

    }// .page--intro



    .contatti--section {

        margin-top: $gutter * 2;

    }// .contatti--section



    .contatti--contact {

        padding: $gutter;

        .container {

            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: $gutter*2;

            @media #{$media-b} { grid-template-columns: 1fr 1fr; }
            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
                // grid-gap: $gutter;
            }

            .title {

                font-size: 4rem;
                font-weight: var(--f-500);
                line-height: 1.1;
                margin-bottom: $gutter * .5;

                @media #{$media-sm} { font-size: 3rem; }

            }// .title

            .form {

                max-width: 80rem;

            }// .form

            .contact {

                .info {

                    &:not(:first-of-type) { margin-top: 2rem; }

                    display: flex;
                    align-items: center;

                    &::before {
            			flex-shrink: 0;
            			content: '';
            			width: 2.6rem;
            			height: 2.6rem;
            			background-repeat: no-repeat;
                        background-position: center;
            			margin-right: 1rem;
                    }

                }// .info

                .email::before { background-image: url(../images/icon-email.svg); }
                .phone::before { background-image: url(../images/icon-phone.svg); }
                .address::before { background-image: url(../images/icon-address.svg); }

                .address { line-height: 1.4; }

            }// .contact

        }// .container

    }// .contatti--contact



    .contatti--brand {

        padding: $gutter;

        .container {

            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: $gutter*2;

            @media #{$media-b} { grid-template-columns: 1fr 1fr; }
            @media #{$media-m} {
                grid-template-columns: repeat(1, 1fr);
                grid-gap: $gutter;
            }

        }// .container

        .image {

            max-height: 45rem;

            .link {

                display: block;
                width: 100%;
                height: 100%;
                position: relative;

                .icon {

                    content: '';
                    display: block;
                    width: 10rem;
                    height: 10rem;
                    color: var(--c-white);
                    background-color: currentColor;
                    border: 1rem solid currentColor;

                    fill: var(--c-grey-light);

                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 5;

                }// .icon

                &:hover,
                &:focus-within {

                    .icon { fill: var(--c-accent); }// .icon

                }

            }// .link

            .img {

                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;

            }// .img

        }// .image

        .content {

            .title {

                font-size: 4rem;
                font-weight: var(--f-500);
                line-height: 1.1;
                margin-bottom: $gutter * .5;

                @media #{$media-sm} { font-size: 3rem; }

            }// .title

            .text {}// .text

            .brands {

                max-width: 60rem;
                margin: auto;
                margin-top: $gutter;

                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                gap: $gutter;

                .brand {

                    height: 6rem;

                    svg {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                }// .brand

                .maico {
                    width: 100%;
                    height: 9rem;
                }

            }// .brands

        }// .content

    }// -contatti--brand



}// .page-template-contatti
